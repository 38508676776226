import Vue from 'vue'

Vue.directive('scroll-to', {
    bind($el, binding) {
        const offset = parseInt(binding.arg, 0)

        // console.log(offset)

        $el.scrollTo = function (e) {
            e.preventDefault()

            const selector = $el.getAttribute('href').replace('/', '')

            const $zone = document.querySelector(selector)

            const topPos = $zone.getBoundingClientRect().top + window.scrollY

            // this changes the scrolling behavior to "smooth"
            window.scroll({
                top: topPos + offset,
                behavior: 'smooth',
            })
        }

        $el.addEventListener('click', $el.scrollTo)
    },
    unbind($el) {
        document.removeEventListener('scroll', $el.$onScroll)
        delete $el.$onScroll
    },
})
