<template>
    <footer class="footer" v-if="pageIsLoaded">
        <section class="pre-footer">
            <div class="left-image" data-inview="revealLeft" data-delay="200">
                <a :aria-label="$t('Lien externe : Directions sur Google map')" href="https://www.google.com/maps/dir//2+de+la+Commune+St+W,+Montreal,+QC+H2Y+4B2/@45.5052122,-73.5528157,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x4cc91af8656617ad:0x7b15369976e22af4!2m2!1d-73.5502408!2d45.5052122!3e0" target="_blank"><img :src="content.contact.image.url" :alt="content.contact.image.titre"></a>
            </div>
            <div class="right-content">
                <h3 class="title" data-inview="fadeInUp" data-delay="300">{{content.contact.titre}}</h3>
                <p class="text" data-inview="fadeInUp" data-delay="400"><span v-html="content.contact.adresse"></span></p>
                <!-- <a href="https://www.google.com/maps/dir//2+de+la+Commune+St+W,+Montreal,+QC+H2Y+4B2/@45.5052122,-73.5528157,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x4cc91af8656617ad:0x7b15369976e22af4!2m2!1d-73.5502408!2d45.5052122!3e0" target="_blank" class="cta"><p class="text">Directions</p></a> -->
            </div>
        </section>
        <div class="partenaires">
            <div class="left">
                <img v-if="content.imageG && content.imageG.url" :src="content.imageG.url" class="logo-left" :alt="content.imageG.titre" />
            </div>
            <div class="right">
                <img
                    v-for="(item, i) in content.listeDeLogos"
                    :key="i"
                    :src="item.url"
                    class="volvo"
                    :alt="item.title ? item.title : item.titre"
                />
            </div>
        </div>
    </footer>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },
}
</script>

<style lang="scss">
@import '@/assets/styles/variables.scss';

.footer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 5vw;
    color: #fff;

    .pricing {
        width: 50%;
        background-color: $color-1;
        padding: 5vw;

        .title {
            font-size: 30px;
            margin-bottom: 10px;
            span {
                color: $color-2;
            }
        }

        .price-wrap {
            width: 100%;
            margin-top: 20px;
            margin-bottom: 30px;

            .price {
                width: 100%;
                display: flex;
                align-content: center;
                justify-content: space-between;
                margin-bottom: 10px;
                position: relative;
                .text {
                    padding-right: 20px;
                    position: relative;
                    z-index: 10;
                    background-color: $color-1;
                    &:last-child {
                        padding-right: 0;
                        padding-left: 20px;
                    }
                }
                &:before {
                    content: '';
                    width: 100%;
                    height: 1px;
                    background-color: rgba(255, 255, 255, 0.1);
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translate(0, -50%);
                    z-index: 1;
                }
            }
            .text {
                &.smaller {
                    font-size: 14px;
                }
            }
        }
    }

    .contact {
        width: 50%;
        background-color: $color-1;
        padding: 5vw;

        .small-title {
            margin-bottom: 10px;
        }

        address {
            color: #fff;
            font-style: normal;
            transition: all 0.4s ease;
            &:hover {
                color: $color-2;
            }
        }

        img {
            margin-bottom: 20px;
        }
    }

    .partenaires {
        width: 100%;
        background-color: $color-3;
        padding: 3vw 5vw 3vw 0;
        display: flex;
        align-content: center;
        justify-content: space-between;

        .left {
            display: flex;
            align-items: center;
        }

        .right {
            display: flex;
            align-items: center;

            img {
                margin-left: 20px;
                width: auto;
                height: 80px;
            }
        }

        .logo-left {
            width: 220px;
        }
    }

    @media screen and (max-width: 1150px) {
        .partenaires {
            .right {
                img {
                    height: 50px;
                }
            }
        }
    }

    @media screen and (max-width: 1024px) {
        margin-top: 10vw;
    }

    @media screen and (max-width: 950px) {
        .partenaires {
            flex-wrap: wrap;
            padding: 7vw 5vw 7vw 0;

            .left {
                margin-bottom: 10px;
            }

            .right {
                padding-left: 5vw;
                flex-wrap: wrap;
                justify-content: center;
                img {
                    margin: 30px;
                }
            }
        }
    }

    @media screen and (max-width: 767px) {
        .pricing {
            width: 100%;
            width: calc(100% - 10vw);
            margin-left: 5vw;
            margin-bottom: 10vw;
            border-radius: 10px;
            padding: 10vw 7vw;

            .title {
                font-size: 24px;
            }
        }
        .contact {
            width: 100%;
            padding: 10vw 5vw;
        }
    }
}
</style>
