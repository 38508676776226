<template>
    <div id="app">
        <NavBar />

        <main class="main-wrapper">
            <transition name="fade" mode="out-in">
                <router-view />
            </transition>
        </main>

        <FooterBar />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NavBar from '@/components/NavBar'
import FooterBar from '@/components/FooterBar'

export default {
    components: { NavBar, FooterBar },

    computed: {
        ...mapGetters(['seo']),
    },

    metaInfo() {
        return {
            ...(this.seo.titleRaw && this.seo.title && { title: this.seo.title }),
            ...(this.seo.titleRaw && { titleTemplate: '%s ' + this.seo.titleRaw[2] }),

            meta: [
                {
                    vmid: 'og:title',
                    property: 'og:title',
                    content: this.seo.title,
                },

                {
                    vmid: 'description',
                    name: 'description',
                    content: this.seo.description,
                },
                {
                    vmid: 'og:description',
                    property: 'og:description',
                    content: this.seo.description,
                },
            ],
            link: [
                {
                    vmid: 'canonical',
                    rel: 'canonical',
                    href: window.location.href,
                },
            ],
        }
    },
}
</script>

<style lang="scss">
@import '@/assets/styles/main.scss';

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    background-color: #fff;
    font-family: nunito, sans-serif;
    font-style: normal;
    font-weight: 600;
    color: $color-1;
}

img {
    display: block;
    height: auto;
    width: 100%;
}

a {
    text-decoration: none;
}

h1,
h2,
h3,
h4,
p {
    margin: 0;
    padding: 0;
}

.fade-enter-active,
.fade-leave-active {
    transition-duration: 0.555s;
    transition-property: opacity, transform;
    transition-timing-function: cubic-bezier(0.35, 0, 0.22, 1);
}

.fade-enter,
.fade-leave-active {
    opacity: 0;
    transform: scale(1.0125) rotate(0.525deg);
}
</style>
