<template>
    <header class="navbar">
        <img
            v-if="$route.meta.lang === 'fr'"
            src="@/assets/img/logo.png"
            class="logo"
            alt="Logo Centre des sciences de Montréal"
        />
        <img
            v-if="$route.meta.lang === 'en'"
            src="@/assets/img/logo-en.png"
            class="logo"
            alt="Logo Montréal Science Centre"
        />
        <div class="right">
            <a
                :href="content.urls.en"
                class="lang"
                v-if="contentIsLoaded && $route.meta.lang === 'fr'"
            >
                <p class="text">EN</p>
            </a>
            <a
                :href="content.urls.fr"
                class="lang"
                v-if="contentIsLoaded && $route.meta.lang === 'en'"
            >
                <p class="text">FR</p>
            </a>

            <a :aria-label="$t('Lien externe : Achetez vos billets')" :href="contentIsLoaded ? content.lienBilleterie : ''" target="_blank" class="cta">
                <p class="text">
                    {{ contentIsLoaded ? content.titreBilleterieCourt : ''
                    }}<span> {{ contentIsLoaded ? content.titreBilleterie : '' }}</span>
                </p>
                <svg
                    class="arrow"
                    viewBox="0 0 25 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M1.38889 11.111C0.621511 11.111 0 10.4895 0 9.72211C0 8.95473 0.621511 8.33322 1.38889 8.33322H23.6111C24.3785 8.33322 25 8.95473 25 9.72211C25 10.4895 24.3785 11.111 23.6111 11.111H1.38889Z"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14.2952 2.37155C13.7535 1.82813 13.7535 0.949689 14.2952 0.406267C14.8386 -0.135422 15.717 -0.135422 16.2605 0.406267L24.5938 8.7396C25.1355 9.28302 25.1355 10.1615 24.5938 10.7049L16.2605 19.0382C15.717 19.5799 14.8386 19.5799 14.2952 19.0382C13.7535 18.4948 13.7535 17.6164 14.2952 17.0729L21.6476 9.72227L14.2952 2.37155Z"
                    />
                </svg>
            </a>
        </div>
    </header>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },
    methods: {
        toogleNavBarStatus() {
            const st = document.documentElement.scrollTop

            if (!document.querySelector('.navbar')) {
                return
            }

            if (st >= 40) {
                document.querySelector('.navbar').classList.add('sticky')
            } else {
                document.querySelector('.navbar').classList.remove('sticky')
            }
        },
    },
    created() {
        // NavBar Follow
        this.toogleNavBarStatus()
        window.addEventListener('scroll', () => {
            this.toogleNavBarStatus()
        })
    },
}
</script>

<style lang="scss">
@import '@/assets/styles/variables.scss';

.navbar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.5vw 5vw;
    transition: all 0.4s ease;

    .logo {
        width: 120px;
        transition: all 0.4s ease;
    }

    .lang {
        margin-right: 20px;
        .text {
            color: #fff;
            transition: all 0.4s ease;
            &:hover {
                color: $color-2;
            }
        }
    }

    .right {
        display: flex;
        align-items: center;
    }

    &.sticky {
        background-color: $color-1;
        padding: 10px 5vw;

        .logo {
            width: 60px;
        }
    }

    @media screen and (max-width: 1024px) {
        .logo {
            width: 80px;
        }
        .lang {
            .text {
                font-size: 14px;
            }
        }
    }

    @media screen and (max-width: 600px) {
        position: absolute;
        padding: 20px 5vw;
        &.sticky {
            background-color: transparent;
            padding: 20px 5vw;

            .logo {
                width: 70px;
            }
        }
        .logo {
            width: 70px;
        }
        .cta {
            .text {
                span {
                    display: none;
                }
            }
        }
    }
}
</style>
